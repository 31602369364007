
.pl {
	display: block;
	margin: auto;
	width: 16em;
	height: auto;
}
.pl line {
	animation-duration: 3s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}
.pl__line1,
.pl__line9 {
	animation-name: line1;
}
.pl__line2,
.pl__line8 {
	animation-name: line2;
}
.pl__line3,
.pl__line7 {
	animation-name: line3;
}
.pl__line4,
.pl__line6 {
	animation-name: line4;
}
.pl__line5 {
	animation-name: line5;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),90%,10%);
		--fg: hsl(var(--hue),90%,90%);
	}
}

/* Animations */
@keyframes line1 {
	from,
	8% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	18% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	28% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	38% {
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	48% {
		opacity: 1;
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	53% {
		opacity: 0;
		stroke-dashoffset: 31.99;
		transform: translate(8px,16px);
	}
	56% {
		animation-timing-function: steps(1,start);
		opacity: 0;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	60% {
		animation-timing-function: ease-out;
		opacity: 1;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	70% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	80% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	90% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	to {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
}
@keyframes line2 {
	from,
	6% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	16% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	26% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	36% {
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	46% {
		opacity: 1;
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	51% {
		opacity: 0;
		stroke-dashoffset: 31.99;
		transform: translate(8px,16px);
	}
	54% {
		animation-timing-function: steps(1,start);
		opacity: 0;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	58% {
		animation-timing-function: ease-out;
		opacity: 1;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	68% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	78% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	88% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	98%,
	to {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
}
@keyframes line3 {
	from,
	4% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	14% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	24% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	34% {
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	44% {
		opacity: 1;
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	49% {
		opacity: 0;
		stroke-dashoffset: 31.99;
		transform: translate(8px,16px);
	}
	52% {
		animation-timing-function: steps(1,start);
		opacity: 0;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	56% {
		animation-timing-function: ease-out;
		opacity: 1;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	66% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	76% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	86% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	96%,
	to {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
}
@keyframes line4 {
	from,
	2% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	12% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	22% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	32% {
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	42% {
		opacity: 1;
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	47% {
		opacity: 0;
		stroke-dashoffset: 31.99;
		transform: translate(8px,16px);
	}
	50% {
		animation-timing-function: steps(1,start);
		opacity: 0;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	54% {
		animation-timing-function: ease-out;
		opacity: 1;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	64% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	74% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	84% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	94%,
	to {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
}
@keyframes line5 {
	from {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	10% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	20% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	30% {
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	40% {
		stroke-dashoffset: -16;
		transform: translate(0,15px);
	}
	50% {
		stroke-dashoffset: -31;
		transform: translate(0,-48px);
	}
	58% {
		stroke-dashoffset: -31;
		transform: translate(0,8px);
	}
	65% {
		stroke-dashoffset: -31.99;
		transform: translate(0,-24px);
	}
	71.99% {
		animation-timing-function: steps(1);
		stroke-dashoffset: -31.99;
		transform: translate(0,-16px);
	}
	72% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	82% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	92%,
	to {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
}

/* Circlurl loader */


/* Loader For report */
        
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100000000;
  }
  .overlay .overlayDoor:before, .overlay .overlayDoor:after {
	content: "";
	position: absolute;
	width: 50%;
	height: 100%;
	background: rgb(255, 255, 255,0.7);
	transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
	transition-delay: 0.8s;
  }
  .overlay .overlayDoor:before {
	left: 0;
  }
  .overlay .overlayDoor:after {
	right: 0;
  }
  .overlay.loaded .overlayDoor:before {
	left: -50%;
  }
  .overlay.loaded .overlayDoor:after {
	right: -50%;
  }
  .overlay.loaded .overlayContent {
	opacity: 0;
	margin-top: -15px;
  }
  .overlay .overlayContent {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }
  .skip{
color:#4046a5;
position: absolute;
font-weight: 400;
font-size: 2em;
left: 48%;
top: 60%;
  }
  /* .overlay .overlayContent .skip {
	display: block;
	width: 130px;
	text-align: center;
	margin: 50px auto 0;
	cursor: pointer;
	color: #fff;
	font-family: "Nunito";
	font-weight: 700;
	padding: 12px 0;
	border: 2px solid #fff;
	border-radius: 3px;
	transition: 0.2s ease;
  }
  .overlay .overlayContent .skip:hover {
	background: #ddd;
	color: #444;
	border-color: #ddd;
  } */
  
  .loader {
	width: 128px;
	height: 128px;
	border: 3px solid #fff;
	border-bottom: 3px solid transparent;
	border-radius: 50%;
	position: relative;
	-webkit-animation: spin 1s linear infinite;
			animation: spin 1s linear infinite;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  .loader .inner {
	width: 64px;
	height: 64px;
	border: 3px solid transparent;
	border-top: 3px solid #fff;
	border-radius: 50%;
	-webkit-animation: spinInner 1s linear infinite;
			animation: spinInner 1s linear infinite;
  }
  
  @-webkit-keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  @-webkit-keyframes spinInner {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(-720deg);
	}
  }
  @keyframes spinInner {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(-720deg);
	}
  }